.player-wrapper {
  width: 50px !important; 
  height: 50px !important; 
}
.react-player {
  padding-top: 56.25% !important;
  position: relative !important;
}

.react-player > div {
  position: absolute !important;
}